.glo {
  width: 100%;
  margin-bottom: 20px;
}
.glo select {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
}
.yo {
  padding: 20px;
  width: 300;
}
