@media only screen and (max-width: 768px) {
  .cald {
    flex-basis: 100% !important;
    width: 100% !important;
  }
  .cald .one {
    flex-basis: 100% !important;
    width: 100% !important;
  }
  .cald .two {
    flex-basis: 100% !important;
    width: 100% !important;
  }
}

.cald {
  display: flex;
  flex-wrap: wrap;
}
.cald .one {
  flex-basis: 40%;
}
.cald .two {
  flex-basis: 60%;
  margin-top: 20px;
}
