/* External CSS file: styles.css */

/* External CSS file: styles.css */

@media print {
  .container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
  }

  .table-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    border: 2px solid black;
  }

  .main-table {
    flex: 1 1 70%;
    padding: 10px;
  }

  .psychomotor-table-container {
    flex: 1 1 30%;
    padding: 10px;
  }

  /* Add additional print styles as needed */
}

/* Add other styles */

.container {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.bd_title {
  flex-grow: 1;
  text-align: center;
}

.f20 {
  font-size: 20px;
}

.f18 {
  font-size: 18px;
}

.bd_detailsarea {
  display: flex;
  justify-content: space-between;
}

.bd_photo img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.bd_detailsareatop,
.bd_detailsareabtm {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.bd_detailsareaon {
  flex-grow: 1;
  margin-right: 20px;
}

.table-responsive {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 2px solid black; /* Border around the table */
}

th,
td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
  color: #042954;
}
#customers th {
  background-color: #ffc107 !important;
}
#customersreport th {
  background-color: #ffc107 !important;
}
.bd_key {
  margin-bottom: 20px;
}

.remarksbox {
  padding: 10px 0;
}
@media print {
  table thead {
    background-color: #ffc107 !important;
  }
  #customers th {
    background-color: #ffc107 !important;
  }
  #customersreport th {
    background-color: #ffc107 !important;
  }
}
@media print {
  .print-table-cell {
    display: table-cell !important;
    vertical-align: top;
  }
}
@media print {
  .main-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd; /* Add borders for better visibility */
  }

  .main-table td {
    padding: 8px; /* Adjust padding as needed */
    vertical-align: top;
  }

  .main-table th {
    padding: 8px;
    text-align: left;
    border-bottom: 2px solid #000; /* Add bottom border to headers */
  }
}

@media print {
  /* Add a class to target the second sub-table */
  .second-sub-table {
    display: block !important;
    width: 100%; /* Adjust width as needed */
    page-break-before: always;
    clear: both; /* Clear the float */
    margin-top: 20px;
  }
}

@media print {
  .main-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000; /* Add borders for better visibility */
  }

  .main-table td {
    padding: 8px; /* Adjust padding as needed */
    vertical-align: top;
  }

  .main-table th {
    padding: 8px;
    text-align: left;
    border-bottom: 2px solid #000; /* Add bottom border to headers */
  }

  #customersreport {
    width: 100%;
    border-collapse: collapse;
  }

  #customersreport th,
  #customersreport td {
    border: 1px solid #000;
    padding: 8px;
  }
}

/* Add more styles as needed */

.table {
  width: 70%;
  border-collapse: collapse;
  border: 2px solid black;
}

.table th,
.table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}
.table-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.table {
  border-collapse: collapse;
  border: 2px solid black; /* Ensure table border is black */
}

.table th,
.table td {
  border: 1px solid black; /* Ensure cell borders are black */
  padding: 8px;
  text-align: left;
  color: #042954;
}

.main-table {
  width: 70%;
}

.second-sub-table {
  width: 30%;
}

#customers th {
  background-color: #ffc107 !important;
}

#customersreport th {
  background-color: #ffc107 !important;
}
